<template>  
  <div class="compreall">
      <div class="cycil">温州 · 鹿城
        <img src="../assets/inspect/yan.png" alt="">
      </div>
      <div class="ContesTitle">
        <div class="titleOne">
          <div>{{numberyear}}</div>
        </div>
        <!-- <div class="titleTwo"></div> -->
        <!-- <div class="spark"></div> -->
      </div>
      <div class="chanciname">
       {{numberchang}} {{opppname}}
      </div>
      <div class="ppada">
        <img src="../assets/inspect/yan.png" alt="">
        <div class="zhihui" v-for="(item,index) in renwulil" :key="index">
          <div class="zhi-title">
            <div>{{item.F_DepName}}</div>
          </div>
          <div class="ddivalue" v-if="item.F_EnabledMark==1">
            未开始
            <div class="sas"></div>
          </div>
          <div class="ddivalue" v-if="item.F_EnabledMark==2">
            评分中
            <div class="sas"></div>
          </div>
          <div class="ddivalue ppk finsh" v-if="item.F_EnabledMark==3">
            已结束
            <div class="sas"></div>
          </div>
          <div class="ddivalue ppk xiaoshu" v-if="item.F_EnabledMark==4">
            <!-- {{item.F_Result}} <span>分</span> -->
            {{item.F_Result}} <span>分</span>
            <div class="sas"></div>
          </div>
          
        </div>
      </div>
      <div class="wenben">
        <div class="cahngci">{{oojname1}}</div>
        <div :class="item.F_EnabledMark==2?'wenbensax inmax':'wenbensax'" @click="pupu(item.F_Id,item.F_Name,1)" v-for="(item,index) in departmentList" :key="index">
          {{item.F_Name}}
        </div>
        <div class="cahngci">{{oojname2}}</div>
        <div :class="item.F_EnabledMark==2?'wenbensax inmax':'wenbensax'" @click="pupu(item.F_Id,item.F_Name,2)" v-for="(item,index) in departmentListlast" :key="index">
          {{item.F_Name}}
        </div>
      </div>
      <div class="uppu" v-if="ispupu">
        <div class="close" @click="ispupu =false">关闭</div>
        <div v-for="(item,index) in ppy" :key="index" class="pukja">
          <div class="itemname">
            {{item.F_Name}}
          </div>
          <div class="xiangq">
            <div class="left sss" v-if="index==0">
              <div :class="index==0&&lkjbnindex1==lk?'lanlink select':'lanlink'" v-for="(kc,lk) in item.deplist" :key="lk" @click="getdepscorelist(index,lk,kc)">
                {{kc.F_DepName}}
              </div>
            </div>
            <div class="left sss" v-else-if="index==1">
              <div :class="index==1&&lkjbnindex2==lk?'lanlink select':'lanlink'" v-for="(kc,lk) in item.deplist" :key="lk" @click="getdepscorelist(index,lk,kc)">
                {{kc.F_DepName}}
              </div>
            </div>
            <div class="left sss" v-else-if="index==2">
              <div :class="index==2&&lkjbnindex3==lk?'lanlink select':'lanlink'" v-for="(kc,lk) in item.deplist" :key="lk" @click="getdepscorelist(index,lk,kc)">
                {{kc.F_DepName}}
              </div>
            </div>
            <div class="left right">
              <div class="lanlink" v-for="(kc,lk) in item.Rolelist" :key="lk">
                  评委{{lk+1}}:{{kc.F_Result}}
              </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import {reactive,onMounted,onUnmounted,ref } from 'vue'
import { toRefs } from '@vue/reactivity'
import {useRoute, useRouter} from "vue-router";
import {getTodaySummary} from '../api/index.js'
import setPromiseInterval, { clearPromiseInterval } from 'set-promise-interval'
import {
  raninfo,
  prolist,
  grouplist,
  deplist,
  depscorelist,
  groupanddeplist,
  depshowlist
} from '../api/index.js'
export default {
  name: 'App',
  components: {
  },
  setup(){
    const obj = reactive(
      {
        ispupu:false,
        ppy:[
          {name:'综合可是组',data:[
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
          ]},
          {name:'综合可是组',data:[
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
          ]},
          {name:'综合可是组',data:[
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
          ]},
          {name:'综合可是组',data:[
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
          ]},
          {name:'综合可是组',data:[
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
          ]},
          {name:'综合可是组',data:[
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
            '部门名称',
          ]},
        ],
        command:[
        ],
        wenben:[
        ],
        chang:[],
        departmentList:[],
        departmentListlast:[],
        opppname:'',
        renwulil:[],
        // renindata:[],
        numberchang:'',
        numberyear:'',
        linkid:'',
        deplistid:'',
        renneesid:'',
        pingweiid:'',
        lineindex:100,
        lkjbnindex1:0,
        lkjbnindex2:0,
        lkjbnindex3:0,
        sdaa:0,
        indexss:0,
        oojname1:'',
        oojname2:'',
      }
    )
    const router = useRouter();
    function getprolist(){
      prolist({}).then(res=>{
        obj.chang = res.data
        obj.pingweiid = res.data[0].F_Id
        obj.oojname1 = res.data[0].F_Name
        // obj.oojname2 = res.data[1].F_Name
        // getgroupanddeplist(res.data[0].F_Id)
        getgrouplistss(res.data[0].F_Id)
        if(res.data.length>=2){
          obj.oojname2 = res.data[1].F_Name
          gesalkjs(res.data[1].F_Id)
        }
        // gesalkjs(res.data[1].F_Id)
      })
    }
    //获取评委评分
    function getlinkl(){
      
      obj.ispupu = true
      obj.lkjbnindex1 = 0
      obj.lkjbnindex2 = 0
      obj.lkjbnindex3 = 0
      // obj.lineindex = 100
      // obj.lkjbnindex = 100
      getgroupanddeplist(obj.pingweiid)
      if(obj.indexss==1){
        getdepscorelist(0,0,obj.ppy[0].deplist[0])
        getdepscorelist(1,0,obj.ppy[1].deplist[0])
        getdepscorelist(2,0,obj.ppy[2].deplist[0])
      } else{
        getdepscorelist(0,0,obj.ppy[0].deplist[0])
        getdepscorelist(1,0,obj.ppy[1].deplist[0])
        getdepscorelist(2,0,obj.ppy[2].deplist[0])
      }
      
      // getgroupanddeplist(obj.pingweiid)
      // depscorelist({
      //   groupId:ids,
      //   depId:ids2
      // }).then(res=>{
      //   obj.renindata = res.data
      // })
    }
    //获取场次列表
    function getgrouplistss(id){
      grouplist({
        projectId:id
      }).then(res=>{
        obj.departmentList = res.data
        // obj.opppname = res.data[0].F_Name
        obj.linkid = res.data[0].F_Id
        
        
      })
    }
    function gesalkjs(id){
      grouplist({
        projectId:id
      }).then(res=>{
        obj.departmentListlast = res.data
      })
    }
    function getdeplist (ids){
      depshowlist({
        // groupId:ids
      }).then(res=>{
        obj.numberchang = res.data.F_ProName
        obj.opppname = res.data.F_GroupName
        obj.numberyear = res.data.F_Year
        localStorage.setItem('F_Year',res.data.F_Year)
        document.title = res.data.F_Year +'年度第二场评议擂台赛'
        // obj.deplistid = ids
        obj.renwulil = res.data.deplist
        // obj.renneesid = ids
        // getdepscorelist(ids,res.data[0].F_DepId)
      })
    }
    function pupu (ids,iname,index){
      // if(index==1){
      //   obj.indexss = 1
      //   obj.pingweiid = obj.chang[0].F_Id
      // }else if(index==2){
      //   obj.indexss = 2
      //   obj.pingweiid = obj.chang[1].F_Id
      // }
      // obj.linkid = ids
      // if(index==1){
      //   obj.numberchang = '第一场'
      // }else if(index==2){
      //   obj.numberchang = '第二场'
      // }
      // obj.opppname = iname
      // deplist({
      //   groupId:ids
      // }).then(res=>{
      //   obj.renwulil = res.data
      //   obj.renneesid = ids
      //   // getdepscorelist(ids,res.data[0].F_DepId)
      // })
    }
    function getgroupanddeplist(id){
      groupanddeplist({
        projectId:id
      }).then(res=>{
        obj.ppy = res.data
        let kll = 0
        // for(var q=0;q<obj.ppy.length;q++){
        //   kll = q
        //   depscorelist({
        //     groupId:obj.ppy[q].deplist[0].F_GroupId,
        //     depId:obj.ppy[q].deplist[0].F_DepId
        //   }).then(ppc=>{
        //     console.log(kll)
        //     obj.ppy[kll].Rolelist = ppc.data
        //     // obj.renindata = res.data
        //   })
        // }
        // console.log(obj.ppy)
      })
    }
    function getdepscorelist(i,i2,kc){
      
      if(i==0){
        obj.lkjbnindex1 = i2
      }else if(i==1){
        obj.lkjbnindex2 = i2
      }else if(i==2){
        obj.lkjbnindex3 = i2
      }
      
        depscorelist({
          groupId:kc.F_GroupId,
          depId:obj.ppy[i].deplist[i2].F_DepId
        }).then(ppc=>{
          obj.ppy[i].Rolelist = ppc.data
          // obj.renindata = res.data
        })
    }
    let bss = ref(0);
    
    onMounted(()=>{
      getdeplist()
      getprolist()
      bss.value = setPromiseInterval(()=> {
        getprolist()
        getdeplist()
        // pupu(obj.linkid,obj.opppname)
      }, 10000)
    })
    onUnmounted(() => {
      clearPromiseInterval(bss.value)
    })
    return{
      ...toRefs(obj),
      // pupu,
      getlinkl,
      getdepscorelist
    }
  },
  methods:{
  }
}
</script>

<style lang="less">
  @import '../css/phys.less';
</style>
